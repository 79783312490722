import React from 'react';
import useUserStateChange from '../../hook/useUserStateChange';
import { env } from '../../env';
import { links } from '../../links';
import './SupportService.scss';

const SupportServiceEmailAddressesOnly = () => {
  const [logged] = useUserStateChange();
  const classes = ['SupportServiceInfoOnly'];
  if (logged) {
    classes.push('UserLoggedIn');
  }
  const renderEpoch = () => {
    const epoch = env.REACT_APP_BILLING_SUPPORT_SITE;
    if (!epoch) return null;

    const epochText = epoch?.slice(epoch.indexOf('epoch')) || '';

    return (
      <>
        or visit EPOCH
        <a href={`mailto:${epoch}`} className="DesignatorValue">{epochText}</a>
      </>
    )
  };

  return (
    <div className={classes.join(' ')}>
      <div className="InfoOnlyWrapper">
        <div className="SupportServiceInfoContent">
          <div className="Designator">{env.REACT_APP_SITE_NAME.replace(/\./g, '')}&nbsp;</div>
          <a href={links.billingAndSupport} className="DesignatorValue">{env.REACT_APP_SUPPORT_EMAIL}</a>
          {renderEpoch()}
        </div>
      </div>
    </div>
  );
};

export default SupportServiceEmailAddressesOnly;